import http from "./http";
export default (publicSellId) => ({
  async index(filters) {
    return http
      .index("publicSells", filters)
      .then((json) => ({ publicSells: json }))
      .catch(async (e) => ({ publicSells: null, error: await e }));
  },
  async get() {
    return http
      .get("publicSells", publicSellId)
      .then((json) => ({ publicSell: json }))
      .catch(async (e) => ({ publicSell: null, error: await e }));
  },
  async create(data) {
    return http
      .post("publicSells", data)
      .then((json) => ({ publicSell: json }))
      .catch(async (e) => ({ publicSell: null, error: await e }));
  },
  async update(data) {
    return http
      .put("publicSells", publicSellId, data)
      .then((json) => ({ publicSell: json }))
      .catch(async (e) => ({ publicSell: null, error: await e }));
  },
  async delete() {
    return http
      .delete("publicSells", publicSellId)
      .then((json) => ({ publicSell: json }))
      .catch(async (e) => ({ publicSell: null, error: await e }));
  },
  Products: (productId) => ({
    async index(filters) {
      return http
        .index(`publicSells/${publicSellId}/products`, filters)
        .then((json) => ({ products: json }))
        .catch(async (e) => ({ products: null, error: await e }));
    },
    async get() {
      return http
        .get(`publicSells/${publicSellId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`publicSells/${publicSellId}/products`, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`publicSells/${publicSellId}/products`, productId, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`publicSells/${publicSellId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
  }),
  Subgroups: (subgroupId) => ({
    async index(filters) {
      return http
        .index(`publicSells/${publicSellId}/subgroups`, filters)
        .then((json) => ({ subgroups: json }))
        .catch(async (e) => ({ subgroups: null, error: await e }));
    },
    async get() {
      return http
        .get(`publicSells/${publicSellId}/subgroups`, subgroupId)
        .then((json) => ({ subgroup: json }))
        .catch(async (e) => ({ subgroup: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`publicSells/${publicSellId}/subgroups`, data)
        .then((json) => ({ subgroup: json }))
        .catch(async (e) => ({ subgroup: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`publicSells/${publicSellId}/subgroups`, subgroupId, data)
        .then((json) => ({ subgroup: json }))
        .catch(async (e) => ({ subgroup: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`publicSells/${publicSellId}/subgroups`, subgroupId)
        .then((json) => ({ subgroup: json }))
        .catch(async (e) => ({ subgroup: null, error: await e }));
    },
  }),
  Reservations: (reservationId) => ({
    async index(filters) {
      return http
        .index(`publicSells/${publicSellId}/reservations`, filters)
        .then((json) => ({ reservations: json }))
        .catch(async (e) => ({ reservations: null, error: await e }));
    },
    async get() {
      return http
        .get(`publicSells/${publicSellId}/reservations`, reservationId)
        .then((json) => ({ reservation: json }))
        .catch(async (e) => ({ reservation: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`publicSells/${publicSellId}/reservations`, data)
        .then((json) => ({ reservation: json }))
        .catch(async (e) => ({ reservation: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`publicSells/${publicSellId}/reservations`, reservationId, data)
        .then((json) => ({ reservation: json }))
        .catch(async (e) => ({ reservation: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`publicSells/${publicSellId}/reservations`, reservationId)
        .then((json) => ({ reservation: json }))
        .catch(async (e) => ({ reservation: null, error: await e }));
    },
  }),
});
